<template>
  <div>
    <div class="container">
      <h1>Page not found - 404</h1>
      <div class="spacer"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Page404'
}
</script>